import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            text: 'HOME',
            link: '/',
            class: 'no-hover-effect header-link home-link'
        },
        {
            text: 'FEATURES',
            link: './#features',
            class: 'no-hover-effect-1 header-link feature-link'
        },
        {
            text: ' PORTFOLIO',
            link: './#portfolio',
            class: 'no-hover-effect-2 header-link portfolio-link'
        },
        // {
        //     text: 'TEAM',
        //     link: '#team',
        //     class: 'no-hover-effect header-link team-link'
        // },
        {
            text: 'CONTACT',
            link: './#contact',
            class: 'no-hover-effect header-link contact-link'
        }
        // {
        //     text: 'Get a Quote',
        //     link: './#contact',
        //     type: 'button',
        //     class: 'get-quote-btn',
        //     btnAttrs: {
        //         design: 'basic-a',
        //         // color: 'white'
        //         class: 'c-btn'
        //     }
        // }
    ];

    const adminMenu = [
        {
            text: 'Admin Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                },
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
