<template>
    <div class="bg-2" id="top">
        <div class="py-4 nav-container">
            <lego-nav-bar drop-down-animation="bounce" shadow="0" class="container bg-transparent" :colored="true"
                          :items="menu">
                <template #logo>
                    <div @click="scrollTop">RED CARPET</div>
                </template>
            </lego-nav-bar>
        </div>

        <div class="">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>

        <btn @click="showGetQuoteForm" class="abs-bottom" size="lg" text="Get a quote">Get a Quote</btn>

        <Footer/>
        <!--Modals-->
        <modal title="Get a Quote" class="c-modal no-close c-p-0 bs-5 notification-modal"
               enter-animation="animated slideInRight"
               exit-animation="animated slideOutRight" ref="GetAQuote"
               :no-close-on-backdrop="false"
               width="100p">
            <!--            <modal title="Get a Quote" class="c-modal no-close c-p-0 bs-6 notification-modal"-->
            <!--               enter-animation="animated slideInUp"-->
            <!--               exit-animation="animated slideOutDown" ref="GetAQuote"-->
            <!--               :no-close-on-backdrop="false"-->
            <!--               width="100p">-->
            <!--        <modal title="Get a Quote" class="c-modal no-close c-p-0 bs-4 notification-modal" ref="GetAQuote"-->
            <!--               :no-close-on-backdrop="false"-->
            <!--               width="100p">-->
            <get-quote-modal></get-quote-modal>
        </modal>
    </div>

</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import getMenu from '../data/menu';
import Footer from '@/views/components/Footer';
import GetQuoteModal from '@components/GetQuoteModal';

export default {
    components: {
        GetQuoteModal,
        Footer,
        LegoNavBar
    },
    data () {
        return {
            menu: getMenu(),
            value: ''
        };
    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout',
    methods: {
        scrollTop () {
            window.scrollTo(0, 0);
        },
        showGetQuoteForm () {
            // alert('abc');
            this.$refs.GetAQuote.show();
        }
    }
};
</script>

<style>
/*.fade-enter-active, .fade-leave-active {*/
/*    transition: opacity .5s;*/
/*}*/

/*.fade-enter, .fade-leave-to !* .fade-leave-active below version 2.1.8 *!*/
/*{*/
/*    opacity: 0;*/
/*}*/
</style>
