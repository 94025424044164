<template>
    <div>
        <!--        GetQuoteModal-->
        <s-form class="row m-0" role="form" @submit="onSubmit"
                autocomplete="off">
            <div class="col-12">
                <validated-input size="sm" name="Name" v-model="name" shadow="0" placeholder="NAME"
                                 :rules="rules.first_name" required/>
                <validated-input size="sm" type="email" name="Email" v-model="email" shadow="0"
                                 placeholder="EMAIL"
                                 :rules="rules.email_id"/>
                <validated-input size="sm" type="number" name="Phone" v-model="phone" shadow="0"
                                 placeholder="PHONE"
                                 :rules="rules.phone"/>
                <validated-text-area size="sm" placeholder="MESSAGE" v-model="message"
                                     :rules="rules.message1"
                                     shadow="0"/>
            </div>
            <div class="col-12 fl-x fl-j-c">
                <button class="lego-btn btn-sm w-50 bg-primary round-1 px-5 text-bold" type="submit">
                    Submit
                </button>
            </div>
        </s-form>
    </div>
</template>

<script>
export default {
    name: 'GetQuoteModal',
    data () {
        return {
            state: 'D',
            name: '',
            email: '',
            phone: '',
            message: '',
            rules: {
                first_name: {
                    required: true
                },
                phone: {
                    required: true
                },
                email_id: {
                    required: true
                },
                message1: {
                    required: true
                }
            }
        };
    }
};
</script>

<style scoped>

</style>
